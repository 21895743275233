





















































































import { Component, Vue } from 'vue-property-decorator';
import StrategyComparisonPesosAndModerateViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/hiring-flow/strategy-comparison-pesos-and-moderate-view-model';

@Component({ name: 'StrategyComparisonPesosAndModerate' })
export default class StrategyComparisonPesosAndModerate extends Vue {
  strategy_comparison_pesos_and_moderate_view_model = Vue.observable(
    new StrategyComparisonPesosAndModerateViewModel(this),
  );

  created() {
    this.strategy_comparison_pesos_and_moderate_view_model.initialize();
  }
}
